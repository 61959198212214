<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div>
        <Header
          :meta="meta"
          :page-title="moduleConfig.page_title"
          :page-image="moduleConfig.page_image"
          :page-kv-image="moduleConfig.page_kv_image"
          :header-section="moduleConfig.header_section"
        />
        <div class="panel"> <!-- 這邊判斷是否啟用，沒有該欄位預設啟用，有填就且為 false 就關掉-->
          <div class="panel__col" v-if="!(moduleConfig.hasOwnProperty('display_current_points')) || moduleConfig.display_current_points">
            <div class="panel__name">{{ moduleConfig.points_name || '總點數' }}</div>
            <div class="panel__value">{{ formattedUserPoints }}</div>
          </div>
          <div class="panel__col" v-if="!(moduleConfig.hasOwnProperty('display_expired_points')) || moduleConfig.display_expired_points">
            <div class="panel__name">
              {{ formattedExpiringSoonPointsDate }}{{ moduleConfig.expired_points_name || '到期點數' }}
            </div>
            <div class="panel__value">{{ formattedExpiringSoonPoints }}</div>
          </div>
        </div>
        <!-- <Tabs
          :tabs="tabs"
          @updateActiveTab="handleUpdateActiveTab"
          :activeTab="activeTab"
        /> -->
        <Tabs v-if="isIndividualTab" :tabs="tabs" :currentTab="currentTab" />
        <div class="content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Tabs from "@/components/Page/Liff/Shared/TabsV2";
import { mapGetters, mapActions } from "vuex";
import themeColor from "@/mixins/liff/themeColor";
import module from "@/mixins/liff/point/main";
import { format } from "date-fns";
import _ from "lodash";

export default {
  mixins: [themeColor, module],
  components: {
    Header,
    Tabs,
  },
  computed: {
    ...mapGetters("liffPoint", [
      "availablePoints",
      "expiringSoonPoints",
      "expiringSoonPointsDate",
    ]),
    isIndividualTab () {
      return !this.$route.name.match(/.*Tab$/)
    },
    formattedUserPoints() {
      return this.availablePoints?.toLocaleString("en-US");
    },
    formattedExpiringSoonPoints() {
      return this.expiringSoonPoints?.toLocaleString("en-US") || "0";
    },
    formattedExpiringSoonPointsDate() {
      if (this.expiringSoonPointsDate) {
        return format(new Date(this.expiringSoonPointsDate), "yyyy/MM/dd");
      }
      return "";
    },
    tabs: function () {
      const tabs = _.sortBy(this.moduleConfig.data_source, "order");
      return tabs.map((tab) => {
        tab.query = this.$route.query.event ? { event: this.$route.query.event } : {}
        return tab
      });
    },
    currentTab: function () {
      return this.$route.path.split("/").reverse()[0];
    },
  },
  data() {
    return {
      themeConfigPage: "points",
      activeTab: "LiffPointsExchange",
      eventCode: null,
      // tabs: [
      //   {
      //     id: "LiffPointsExchange",
      //     text: "點數兌換",
      //   },
      //   {
      //     id: "LiffPointsExchangeRecords",
      //     text: "兌換紀錄",
      //   },
      //   {
      //     id: "LiffPointsRechargeRecords",
      //     text: "入點紀錄",
      //   },
      // ],
    };
  },
  created() {
    this.activeTab = this.$route.name;
    this.eventCode = this.$route.query.event ?? null;
  },
  mounted() {
    if (this.$route.name == "LiffPoint") {
      const isEndWithSlash = this.$route.path.slice(-1) == "/";
      const path = isEndWithSlash
        ? this.tabs.sort((a, b) => a.order - b.order)[0].path
        : `${this.$route.path}/${
            this.tabs.sort((a, b) => a.order - b.order)[0].path
          }`;
      this.$router.push({ path });
    }

    this.fetchAvailablePoints({ eventCode: this.eventCode });
    this.fetchExpiringSoonPoints({ eventCode: this.eventCode });
  },
  methods: {
    ...mapActions("liffPoint", [
      "fetchAvailablePoints",
      "fetchExpiringSoonPoints",
    ]),
    handleUpdateActiveTab(tabID) {
      this.activeTab = tabID;

      if (this.eventCode) {
        this.$router.push({ name: tabID, query: { event: this.eventCode } });
      } else {
        this.$router.push({ name: tabID });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--liff-layout_bg_color);
  min-height: 100vh;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  padding-bottom: 1.5rem;
}


.content {
  background: var(--liff-content_bg_color);
}

::v-deep .panel {
  display: flex;
  padding: 19px 12px;
  border-bottom: 1px solid #e5e5ea;
  background-color: var(--s-white);

  .panel__col {
    width: 50%;
  }

  .panel__name {
    color: var(--liff-secondary_text_color);
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 4px;
  }
  .panel__value {
    color: var(--s-primary);
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
}

::v-deep .pointes-list {
  height: calc(100vh - 56px - 84px - 52px);
  overflow-y: auto;
}
</style>
